<template>
  <v-container>
    <v-card class="pb-6">
      <v-toolbar flat color="blue" dark>
        <v-toolbar-title>Check database health</v-toolbar-title
        ><v-spacer></v-spacer>
      </v-toolbar>
      <v-card color="blue lighten-5" flat class="mx-6 mt-6">
        <v-card-title>Read this</v-card-title>
        <v-card-text>
          <v-row dense
            ><v-col cols="12"
              ><div>
                Use below action button to start the search for orphaned
                objectes in the database.The table below shows any orphaned
                objects found in the database. An orphaned object is either a
                collection of bonuses, a routeplan (collection of routestops) or
                a collection of files for which no parent rally object exists.
                Orphaned objects are an anomaly and they shouldn't happen. If
                you find orphaned objects, it either means there is a bug in the
                LD Rally and Ride Planner software or a database operation was
                interrupted before it could fully complete (typically network
                connection issues). Orphaned objects typically are objects you
                intended to delete when you deleted the parent rally object. You
                should permanently delete the orphaned objects after you have
                determined these are no longer needed. Before permanently
                deleting an object, make sure to check out the contents (file or
                JSON object). NOTE: running this orphan detection takes a minute
                or so the first time since the Google Firebase Cloud Function
                on-demand compiler takes a while to complete.
              </div></v-col
            ></v-row
          >
        </v-card-text>
        <v-card-actions>
          <v-row
            ><v-col cols="12"
              ><v-btn :loading="buttonLoading" @click="findOrphans"
                >Check database health</v-btn
              ></v-col
            ></v-row
          ></v-card-actions
        >
      </v-card>
      <v-card
        v-if="collections.length > 0"
        color="blue lighten-5"
        flat
        class="mx-6 mt-6"
      >
        <v-card-title>Orphaned collections</v-card-title>
        <v-card-text>
          <v-row
            ><v-col cols="12"
              ><div>
                Click on a row in below table to show the objects in an orphaned
                collection
              </div></v-col
            ></v-row
          >
          <v-row dense>
            <v-col cols="12">
              <v-data-table
                dense
                disable-pagination
                single-select
                hide-default-footer
                :headers="tableHeadersCollections"
                :items="collections"
                item-key="id"
                class="blue lighten-5"
                @click:row="showObject"
              >
                <template v-slot:item.actions="{ item }"
                  ><v-btn
                    v-if="item.type != 'File collection'"
                    icon
                    :href="downloadCollection()"
                    :download="item.id + '.json'"
                    ><v-icon small>mdi-download</v-icon></v-btn
                  >
                  <v-btn v-if="item.type === 'File collection'" icon disabled
                    ><v-icon small icon>mdi-download-off</v-icon></v-btn
                  >
                  <v-btn icon @click="deleteCollection(item)"
                    ><v-icon small>mdi-delete</v-icon></v-btn
                  ></template
                >
              </v-data-table></v-col
            >
          </v-row>
        </v-card-text>
      </v-card>
      <v-card
        v-if="objects.length > 0"
        color="blue lighten-5"
        flat
        class="mx-6 mt-6"
      >
        <v-card-title>{{ collectiontitle }}</v-card-title>
        <v-card-text>
          <v-row
            ><v-col cols="12"
              ><div>
                Click on a row in below table to show the object data for the
                selected object
              </div></v-col
            ></v-row
          >
          <v-row dense>
            <v-col cols="12">
              <v-data-table
                dense
                disable-pagination
                single-select
                hide-default-footer
                :headers="tableHeadersObjects"
                :items="objects"
                item-key="id"
                class="blue lighten-5"
                @click:row="showData"
              >
                <template v-slot:item.actions="{ item }"
                  ><v-btn
                    icon
                    :href="downloadObject(item)"
                    :download="item.id + '.json'"
                    ><v-icon small>mdi-download</v-icon></v-btn
                  >
                  <v-btn icon @click="deleteObject(item)"
                    ><v-icon small>mdi-delete</v-icon></v-btn
                  ></template
                >
              </v-data-table></v-col
            >
          </v-row>
        </v-card-text></v-card
      >
      <v-card
        v-if="objectdata != null"
        color="blue lighten-5"
        flat
        class="mx-6 mt-6"
        ><v-card-title>{{ objecttitle }}</v-card-title>
        <v-card-text>
          <v-row dense
            ><v-col cols="12"
              ><div>
                <pre>{{ objectdata }}</pre>
              </div>
            </v-col></v-row
          >
        </v-card-text></v-card
      ></v-card
    >
  </v-container>
</template>

<script>
import { db, cloudfunctions, storage } from '@/firebaseConfig.js'
import { mapState } from 'vuex'
export default {
  name: 'Repair',
  data() {
    const data = {
      tableHeadersObjects: [
        { text: 'Object ID', align: 'start', sortable: true, value: 'id' },
        { text: 'Path', align: 'start', sortable: true, value: 'path' },
        { text: 'Actions', sortable: false, value: 'actions' }
      ],
      tableHeadersCollections: [
        { text: 'Orphan ID', align: 'start', sortable: true, value: 'id' },
        { text: 'Type', align: 'start', sortable: true, value: 'type' },
        {
          text: 'Object count',
          align: 'start',
          sortable: true,
          value: 'count'
        },
        { text: 'Actions', sortable: false, value: 'actions' }
      ],
      buttonLoading: false,
      collections: [],
      objects: [],
      objectdata: null,
      objecttitle: 'hello world',
      collectiontitle: 'hello world',
      objectsFiles: [],
      objectsRoutestops: [],
      objectsBonuslocations: []
    }
    return { ...data }
  },
  computed: {
    ...mapState({
      CurrentUser: (state) => state.moduleUser.CurrentUser
    })
  },
  methods: {
    downloadCollection() {
      let output = []
      this.objects.forEach((object) => {
        output.push(object.data)
      })
      return (
        'data:application/json;charset=utf-8,' +
        encodeURIComponent(JSON.stringify(output))
      )
    },
    downloadObject(_item) {
      if (_item.type === 'file') {
        return _item.data
      }
      return (
        'data:application/json;charset=utf-8,' +
        encodeURIComponent(JSON.stringify(_item.data))
      )
    },
    async deleteCollection(_item) {
      let objectsToBeDeleted = []
      switch (_item.type) {
        case 'File collection':
          objectsToBeDeleted = [...this.objectsFiles]
          break
        case 'Bonus collection':
          objectsToBeDeleted = [...this.objectsBonuslocations]
          break
        case 'Routestop collection':
          objectsToBeDeleted = [...this.objectsRoutestops]
          break
      }
      objectsToBeDeleted.forEach(async (object) => {
        this.deleteObject(object)
      })
    },
    async deleteObject(_item) {
      this.objectdata = null
      const collectionpath = _item.path.replace(`/${_item.id}`, '')
      const collectionindex = collectionpath.lastIndexOf('/')
      const collectionid = collectionpath.substring(collectionindex + 1)
      if (_item.type === 'bonus' || _item.type === 'routestop') {
        await db.collection(collectionpath).doc(_item.id).delete()
      } else {
        //this must be a file to be deleted
        await storage.ref(_item.path).delete()
      }
      this.objects.splice(this.objects.indexOf(_item), 1)
      if (this.objects.length === 0) {
        // when the last object is deleted from a collection, the collection is automatically deleted as well
        const collection = this.collections.find(
          (item) => item.id === collectionid
        )
        this.collections.splice(this.collections.indexOf(collection), 1)
      }
    },
    showData(_item, _row) {
      if (_item.type === 'file') return
      _row.select(true)
      this.objecttitle = `Object : ${_item.id}`
      this.objectdata = _item.data
    },
    async showObject(_item, _row) {
      _row.select(true)
      this.objectdata = null
      this.collectiontitle = `${_item.type} : ${_item.id}`
      switch (_item.type) {
        case 'File collection':
          this.objects = this.objectsFiles
          break
        case 'Bonus collection':
          this.objects = this.objectsBonuslocations
          break
        case 'Routestop collection':
          this.objects = this.objectsRoutestops
          break
      }
    },
    async findOrphans() {
      this.buttonLoading = true
      this.objects = []
      this.collections = []
      this.objectdata = null
      const getSubCollections =
        cloudfunctions.httpsCallable('getSubCollections')

      // retrieve the objectIDs of all rallies in the firestore database
      const rallies = await db
        .collection(`users/${this.CurrentUser.uid}/rallies`)
        .get()

      // retrieve the objectIDs of all bonuslocation collections in the firestore database
      const bonuslocations = await getSubCollections({
        docPath: `users/${this.CurrentUser.uid}/rallies/bonuslocations`
      })
      bonuslocations.data.collections.forEach(async (bonuslocation) => {
        if (
          !rallies.docs.reduce((sum, current) => {
            if (current.data().rallyid === bonuslocation) sum = true
            return sum
          }, false)
        ) {
          const bonuslocations = await db
            .collection(
              `users/${this.CurrentUser.uid}/rallies/bonuslocations/${bonuslocation}`
            )
            .get()
          bonuslocations.docs.forEach((bonuslocation) => {
            this.objectsBonuslocations.push({
              path: bonuslocation.ref.path,
              id: bonuslocation.id,
              data: bonuslocation.data(),
              type: 'bonus'
            })
          })
          this.collections.push({
            id: bonuslocation,
            type: 'Bonus collection',
            count: bonuslocations.docs.length
          })
        }
      })

      // retrieve the objectIDs of all Routestop collections in the firestore database
      const routestops = await getSubCollections({
        docPath: `users/${this.CurrentUser.uid}/rallies/routestops`
      })
      routestops.data.collections.forEach(async (routestop) => {
        if (
          !rallies.docs.reduce((sum, current) => {
            if (current.data().rallyid === routestop) sum = true
            return sum
          }, false)
        ) {
          const routestops = await db
            .collection(
              `users/${this.CurrentUser.uid}/rallies/routestops/${routestop}`
            )
            .get()
          routestops.docs.forEach((routestop) => {
            this.objectsRoutestops.push({
              path: routestop.ref.path,
              id: routestop.id,
              data: routestop.data(),
              type: 'routestop'
            })
          })
          this.collections.push({
            id: routestop,
            type: 'Routestop collection',
            count: routestops.docs.length
          })
        }
      })

      let directoryContent = await storage
        .ref(`users/${this.CurrentUser.uid}/rallybooks`)
        .listAll()

      // retrieve the objectIDs of all File collections in the firestore storage
      directoryContent.prefixes.forEach(async (prefix) => {
        if (
          !rallies.docs.reduce((sum, current) => {
            if (current.data().rallyid === prefix.name) sum = true
            return sum
          }, false)
        ) {
          const files = await storage
            .ref(`users/${this.CurrentUser.uid}/rallybooks/${prefix.name}`)
            .listAll()
          files.items.forEach(async (file) => {
            this.objectsFiles.push({
              path: file.fullPath,
              id: file.name,
              data: await storage.ref(file.fullPath).getDownloadURL(),
              type: 'file'
            })
          })
          this.collections.push({
            id: prefix.name,
            type: 'File collection',
            count: files.items.length
          })
        }
      })
      this.buttonLoading = false
    }
  }
}
</script>

<style>
tr.v-data-table__selected {
  background: #bbdefb !important;
}
</style>
